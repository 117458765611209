import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

const CenterText = styled.div`
  font-size: 1.3333rem;
  line-height: 1.15;
  width: 100%;
  /* margin-left: 4rem; */
  margin-bottom: 1.6666rem;
  color: white;
  transition: opacity 0.2s;
  opacity: ${(props) => props.hide && 0};

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 70px;

      ${up('tablet')} {
        top: 80px;
      }
    `}

  ${up('tablet')} {
    margin-left: 17rem;
    width: 40%;
  }

  a {
    color: white;
  }

  p {
    margin-bottom: 1em;
  }
`;

export default CenterText;
